import Axios, { AxiosHeaders, AxiosRequestHeaders } from 'axios';
import { getCredentials } from '../commons';
import { store } from '../redux/app/store';
import { unauth } from '../redux/slices/who_am_i.slice';

export const endpoints: Record<string, string> = Object.freeze({
  default: 'https://fog-admin-stg.foodbag.app',
  // development: 'http://localhost:40025',
  development: 'https://fog-admin-stg.foodbag.app',
  staging: 'https://fog-admin-stg.foodbag.app',
  production: 'https://fog-admin-prd.foodbag.app',
});

type NonUndefined<T, E = undefined> = Pick<
  T,
  {
    [Prop in keyof T]: T[Prop] extends E ? never : Prop;
  }[keyof T]
>;

interface Headers extends AxiosRequestHeaders {
  'X-API-KEY': string;
  'X-UID': string;
}

type CustomAxiosHeaders = NonUndefined<Headers>;

// See - https://stackoverflow.com/a/72690343/3501729
export const getApiEndpoint = () => {
  console.log({
    env: process.env?.REACT_APP_ENVIRONMENT,
    baseUrl: endpoints[process.env?.REACT_APP_ENVIRONMENT ?? 'default'],
  });
  return endpoints[process.env?.REACT_APP_ENVIRONMENT ?? 'default'] || endpoints.default;
};

const AxiosInstance = Axios.create({
  baseURL: getApiEndpoint(),
  // see https://axios-http.com/docs/req_config
  // withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: (status) => {
    // Treat 404 as success with empty payload
    return (status >= 200 && status < 300) || status === 404;
  },
});

AxiosInstance.interceptors.request.use(
  function(config: any) {
    const { token, uid } = getCredentials();
    const h: CustomAxiosHeaders = {
      ...config.headers,
      'X-API-KEY': token,
      'x-UID': uid,
    };

    return { ...config, headers: h };
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

AxiosInstance.interceptors.response.use(
  (response) => {
    const { status, data } = response;
    if (data.isError && !((status >= 200 && status < 300) || status === 404)) {
      return Promise.reject(response?.data?.message ?? 'There was a fatal error from our end.');
    }
    return data?.payload ?? [];
  },
  (error) => {
    if (error.response) {
      const { status, statusText, data } = error.response;
      console.log(status, statusText);
      if (status === 401) {
        store.dispatch(unauth());
      }
      if (data) {
        return Promise.reject(data?.message ?? statusText);
      }

      return Promise.reject('Error form axios interceptor');
    }
    return Promise.reject(error?.message ?? 'Timeout exceeded');
  }
);

export default AxiosInstance;
